import { useDispatch } from "react-redux";
// STORE
import { toggleCommonModalShowStatus, updateSignUpModalState } from "@/store/System/actions";
// CONSTANTS
import { systemModals } from "@/shared/constants/system";

const useToggleCommonModalHandlers = () => {
  const dispatch = useDispatch();

  const signInHandler = ({ show } = {}) => {
    dispatch(toggleCommonModalShowStatus({
      show,
      modalKey: systemModals.SIGN_IN,
    }));
  };

  const signUpHandler = ({ show, campaignName, userEmail } = {}) => {
    dispatch(updateSignUpModalState({
      show,
      campaignName,
      userEmail
    }));
  };

  const contactUsHandler = ({ show } = {}) => {
    dispatch(toggleCommonModalShowStatus({
      show,
      modalKey: systemModals.CONTACT_US,
    }));
  };

  const editProfileHandler = ({ show } = {}) => {
    dispatch(toggleCommonModalShowStatus({
      show,
      modalKey: systemModals.PROFILE,
    }));
  };

  const enterCampaignPasscodeHandler = ({ show } = {}) => {
    dispatch(toggleCommonModalShowStatus({
      show,
      modalKey: systemModals.ENTER_CODE,
    }));
  };

  const restorePasswordHandler = ({ show } = {}) => {
    dispatch(toggleCommonModalShowStatus({
      show,
      modalKey: systemModals.RESTORE_PASSWORD,
    }));
  };

  const reportsGlobalHandler = ({ show } = {}) => {
    dispatch(toggleCommonModalShowStatus({
      show,
      modalKey: systemModals.REPORTS_GLOBAL,
    }));
  };

  return {
    signIn: signInHandler,
    signUp: signUpHandler,
    restorePassword: restorePasswordHandler,
    contactUs: contactUsHandler,
    editProfile: editProfileHandler,
    enterCampaignPasscode: enterCampaignPasscodeHandler,
    reportsGlobal: reportsGlobalHandler
  };
};

export default useToggleCommonModalHandlers;
