import React, { useEffect, useRef } from "react";
// MATERIAL UI
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AsrCloseModalButton from "@/shared/components/Buttons/AsrCloseModalButton/AsrCloseModalButton";
import Logo from "@/components/AppHeader/components/Logo/Logo";
import ChooseLanguage from "@/components/Common/ChooseLanguage";
import Dialog from "@mui/material/Dialog";
// STYLES
import styles from "./styles.module.scss";
import Typography from "@mui/material/Typography";

const screens = {
  DESKTOP: "desktop",
  TABLETS: "tablets",
};

const FullScreenDialogFunc = ({
                                show,
                                setShow,
                                children,
                                title,
                                content,

                                removeContainerTabIndex = false,
                                showLanguageSelector = true
                              }) => {
  const dialogRef = useRef();

  const doRemoveContainerTabIndex = () => {
    if (!dialogRef.current) return;
    if (!removeContainerTabIndex) return;

    const container = dialogRef.current.querySelector(".MuiDialog-container");
    if (!container) return;
    container.removeAttribute("tabindex");
  };

  useEffect(() => {
    doRemoveContainerTabIndex();
  }, [dialogRef.current, doRemoveContainerTabIndex]);

  const generateMainContent = (screen) => (
    <div
      className={`${styles.mainContent} ${screen === screens.DESKTOP ? styles.mainContentDesktop : styles.mainContentTablets}`}>
      {/*  TITLE  */}
      {title && (
        <Typography variant="subtitle1" style={{
          flex: 1,
          marginRight: "20px",
          color: "white"
        }}>
          {title}
        </Typography>
      )}
      {/*  CONTENT  */}
      {content && (
        <>
          {content}
        </>
      )}
    </div>
  );

  const handleClose = () => {
    setShow(false);
  };

  if (!show) return null;
  return (
    <div>
      <Dialog
        fullScreen
        open={show}
        onClose={handleClose}
        disableEscapeKeyDown={true}
        scroll='body'
        classes={{
          root: styles.dialogRoot
        }}
        ref={dialogRef}
      >
        <AppBar position='static' className={styles.fullScreenDialogHeader}>
          <Toolbar
            classes={{
              gutters: styles.headerToolbar
            }}
          >
            {/*  LEFT CONTENT */}
            <div className={styles.leftContent}>
              <AsrCloseModalButton
                handler={handleClose}
                rootClassName={styles.fullScreenDialogCloseBtn}
                isVisible/>
              <Logo disableLink={true}/>
            </div>
            {/*  MAIN CONTENT   */}
            {generateMainContent(screens.DESKTOP)}
            {/* LANGUAGE */}
            {showLanguageSelector && (
              <div className={styles.rightContent}>
                <ChooseLanguage textColor=''/>
              </div>
            )}
          </Toolbar>
          {generateMainContent(screens.TABLETS)}
        </AppBar>
        {children}
      </Dialog>
    </div>
  );
};

export default FullScreenDialogFunc;