import React from "react";
import FullScreenDialogFunc from "@/components/Common/FullScreenDialog";
import styles from "./styles.module.scss";

const SupersetEmbeddedFullScreenModal = ({ show, closeModal }) => {

  const generateIframeSrc = () => {
    return process.env.REACT_APP_SUPERSET_HOST;
  };

  return (
    <FullScreenDialogFunc
      show={show}
      setShow={closeModal}
      showLanguageSelector={false}
    >
      <iframe
        src={generateIframeSrc()}
        className={styles.iframeContainer}
      />
    </FullScreenDialogFunc>
  );
};

export default SupersetEmbeddedFullScreenModal;
