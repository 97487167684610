import React from "react";
import { useSelector } from "react-redux";
// COMPONENTS
import ContactUsModal from "../ContactUsModal";
import AllInOneAuthLightModal from "@/components/Auth/Modals/AllInOneAuthLightModal";
import ProfileEditorModal from "@/components/Profile/Modals/ProfileEditorModal";
import SupersetEmbeddedFullScreenModal
  from "@/components/BusinessIntelligence/Superset/Modals/SupersetEmbeddedFullScreenModal";
// STORE
import { commonAppModals } from "@/store/System/selectors";
// HOOKS
import useToggleCommonModalHandlers from "./hooks/useToggleCommonModalHandlers";
// CONSTANTS
import { systemModals } from "@/shared/constants/system";


const CommonAppModals = () => {
  const modalsState = useSelector(commonAppModals);
  const showContactUsModal = modalsState?.[systemModals.CONTACT_US]?.show || false;
  const showEditProfileModal = modalsState?.[systemModals.PROFILE]?.show || false;
  const showReportsGlobalModal = modalsState?.[systemModals.REPORTS_GLOBAL]?.show || false;
  const modalToggleHandlers = useToggleCommonModalHandlers();

  return (
    <>
      {showContactUsModal && (
        <ContactUsModal
          show={showContactUsModal}
          closeModal={modalToggleHandlers.contactUs}
        />
      )}

      {showEditProfileModal && (
        <ProfileEditorModal
          open={showEditProfileModal}
          closeModal={modalToggleHandlers.editProfile}
        />
      )}

      {showReportsGlobalModal && (
        <SupersetEmbeddedFullScreenModal
          show={showReportsGlobalModal}
          closeModal={modalToggleHandlers.reportsGlobal}
        />
      )}

      <AllInOneAuthLightModal/>
    </>
  );
};

export default CommonAppModals;